import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ReactPixel from "react-facebook-pixel";
import GA4React from "ga-4-react";
import { Router } from "./router.jsx";
import "./reset.css";
ReactPixel.init("3397938457086835", {});

function Manager() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NS9RSZ4" });
  }, []);
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Manager />
        <Router />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

try {
  setTimeout((_) => {
    const ga4react = new GA4React("G-94E0DXYV2H");
    ga4react.initialize().catch((err) => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}

reportWebVitals();
