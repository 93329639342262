import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { AppLoader } from "./components/loading";
const Error = lazy(() => import("./pages/error/index"));
const Home = lazy(() => import("./pages/homepage/index"));

export function Router(props) {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/*" element={<Error />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Suspense>
  );
}
